






























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import FaqBox from '@/components/FaqBox.vue';
import CouponUsed from '@/pages/P0918/CouponUsed.vue';
import ScrollArea from '@/pages/P0918/ScrollArea.vue';
import AboutTicket from '@/pages/P0918/AboutTicket.vue';
import App from '@/pages/P0918/App.vue';

@Component({
  components: { FaqBox, CouponUsed, ScrollArea, AboutTicket, App }
})
export default class P0919 extends Vue {
  activeTabName = 'express';
  isOpenCoupon01 = false;
  isOpenCoupon02 = false;
  isOpenCoupon03 = false;
  isOpenCoupon04 = false;
}
